import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Icon, Strong, Image, Link, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone, MdHelp } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services/company-incorporation"} />
		<Helmet>
			<title>
				Company Incorporation in Cyprus | Taskmasters
			</title>
			<meta name={"description"} content={"Incorporating a company in Cyprus can provide numerous benefits for businesses seeking tax optimization, international expansion, and a favorable regulatory environment."} />
			<meta property={"og:title"} content={"Company Incorporation in Cyprus | Taskmasters"} />
			<meta property={"og:description"} content={"Incorporating a company in Cyprus can provide numerous benefits for businesses seeking tax optimization, international expansion, and a favorable regulatory environment."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/ogimage.png?v=2024-02-14T13:32:07.319Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:47.550Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:57.135Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:57.135Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:57.135Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:57.135Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:25:03.164Z"} />
		</Helmet>
		<Components.Header />
		<Section quarkly-title="Hero header 1 /1/" padding="112px 0 112px 0" sm-padding="64px 0 64px 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				display="flex"
				justify-content="space-between"
				sm-flex-direction="column"
				grid-gap="80px"
			/>
			<Box
				display="flex"
				flex-direction="column"
				justify-content="center"
				grid-gap="24px"
				width="100%"
				sm-width="100%"
				md-width="100%"
				lg-width="100%"
				lg-display="grid"
				align-items="center"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--headline1"
					as="h1"
					color="--primary"
					sm-font="--headline2"
					text-align="center"
				>
					Company Incorporation
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--darkL2"
					max-width="800px"
					text-align="center"
				>
					Incorporating a company in Cyprus can provide numerous benefits for businesses seeking tax optimization, international expansion, and a favorable regulatory environment.
				</Text>
			</Box>
		</Section>
		<Section
			padding="80px 0 80px 0"
			sm-padding="60px 0 60px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Schedule-8"
			background="--color-lightD1"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Advantages
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Incorporating a company in Cyprus can offer several advantages
				</Text>
			</Box>
			<Box
				width="60%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px 32px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				md-grid-gap="36px "
			>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="32px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Tax Benefits
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Cyprus has one of the lowest corporate tax rates in the EU, making it attractive for businesses looking to minimize their tax liabilities.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="32px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								as="h1"
								margin="0px 0px 0px 0px"
								font="--lead"
								color="--dark"
								width="100%"
								md-width="100%"
								text-align="left"
							>
								Double Tax Treaties
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Cyprus has an extensive network of double tax treaties with over 60 countries, making it easier for companies to conduct international business while minimizing the risk of double taxation.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="32px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							EU Membership
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Cyprus is a member of the European Union, providing companies with access to the EU market and its regulatory framework.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="32px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Strategic Location
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Situated at the crossroads of Europe, Asia, and Africa, Cyprus offers a strategic location for businesses looking to expand globally.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="32px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Ease of Doing Business
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Cyprus has a well-established legal system, transparent regulatory framework, and efficient business environment, making it easier for companies to set up and operate.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="32px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Asset Protection
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Cyprus offers robust asset protection mechanisms, including favorable corporate laws and regulations.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="32px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							English Language
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							English is widely spoken and used in business transactions in Cyprus, which can simplify communication for international companies.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Box display="flex" flex-direction="column" padding="0px 0px 0px 48px">
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Overall, incorporating a company in Cyprus can provide numerous benefits for businesses seeking tax optimization, international expansion, and a favorable regulatory environment.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-5">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				flex-direction="column"
				margin="0px 0px 90px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="--headline2"
					color="--primary"
					border-color="--color-dark"
					text-align="center"
				>
					How it works
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="center"
					md-text-align="center"
					sm-text-align="center"
					max-width="800px"
					sm-margin="0px 0px 16px 0px"
				>
					To incorporate a company in Cyprus, you typically need to follow these steps
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				lg-padding="0px 0 0px 0"
				flex-direction="column"
				align-self="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-primary"
						display="flex"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						align-items="center"
						justify-content="center"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							1
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						border-width="0 0 0 1px"
						border-style="solid"
						border-color="#c8ced7"
						padding="0px 0px 0px 20px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--primary"
							font="--lead"
							text-align="left"
						>
							Choose a Company Name
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							Select a unique name for your company that complies with Cyprus naming regulations.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-primary"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							2
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--primary"
							font="--lead"
							text-align="left"
						>
							Appoint Directors and Shareholders
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							Decide on the directors and shareholders of the company. At least one director and one shareholder are required, and they can be individuals or corporate entities.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-primary"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							3
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--primary"
							font="--lead"
							text-align="left"
						>
							Registered Office Address
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							You'll need to provide a registered office address in Cyprus where official correspondence will be sent.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-primary"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							4
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--primary"
							font="--lead"
							text-align="left"
						>
							Prepare Documents
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							Prepare the necessary documents, including the company's memorandum and articles of association, director and shareholder declarations, and other incorporation forms.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-primary"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							5
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--primary"
							font="--lead"
							text-align="left"
						>
							Submit Application
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							File the incorporation documents and pay the necessary fees to the Cyprus Registrar of Companies.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-primary"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							6
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--primary"
							font="--lead"
							text-align="left"
						>
							Obtain Tax Identification Number (TIN)
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							Once the company is registered, you'll need to obtain a Tax Identification Number (TIN) from the Cyprus Tax Department.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-primary"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							7
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--primary"
							font="--lead"
							text-align="left"
						>
							Open Bank Account
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							Open a corporate bank account in Cyprus to manage the company's finances.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-primary"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							8
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--primary"
							font="--lead"
							text-align="left"
						>
							Comply with Regulatory Requirements
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							Ensure compliance with all regulatory requirements, including annual filings, tax obligations, and any other legal obligations.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-primary"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							9
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--primary"
							font="--lead"
							text-align="left"
						>
							Obtain Necessary Licenses
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
							max-width="800px"
						>
							Depending on the nature of your business activities, you may need to obtain specific licenses or permits from relevant authorities.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 0 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-primary"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="white" font="--lead" text-align="center">
							10
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--primary"
							font="--lead"
							text-align="left"
						>
							Legal Advice
						</Text>
						<Text
							margin="0 0 0 25px"
							font="--base"
							color="--darkL2"
							text-align="left"
							border-color="#7a7c7f"
							max-width="800px"
						>
							Consider seeking legal advice from a local lawyer or corporate service provider to ensure compliance with all legal and regulatory requirements.
						</Text>
					</Box>
				</Box>
			</Box>
			<Text
				as="h1"
				margin="23px 0px 0px 0px"
				font="--lead"
				color="--dark"
				width="100%"
				md-width="100%"
				md-margin="0px 0px 32px 0px"
				md-padding="0px 0px 0px 0px"
				text-align="center"
				md-text-align="center"
				sm-text-align="center"
				max-width="800px"
				sm-margin="20px 0px 12px 0px"
			>
				It's essential to familiarize yourself with the specific procedures and requirements for incorporating a company in Cyprus, as they may vary depending on the type of company and business activities. Working with experienced professionals can help streamline the process and ensure compliance with all relevant laws and regulations.
			</Text>
		</Section>
		<Section padding="100px 0 120px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-10" background="white">
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="--primary"
				font="--base"
				border-color="#dca654"
				text-transform="uppercase"
			>
				Proof of our expertise
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				lg-flex-direction="column"
				grid-gap="24px"
			>
				<Box min-width="100px" min-height="100px" width="50%" lg-width="100%">
					<Text margin="0 0px 0px 0px" font="--headline2" color="--darkL2" md-font="--headline3">
						Explore our certifications showcasing our expertise in business administration and accounting services for companies
					</Text>
				</Box>
				<Box
					display="flex"
					width="50%"
					lg-width="100%"
					lg-margin="20px 0px 0px 0px"
					md-flex-direction="column"
					md-display="flex"
					md-grid-gap="40px"
					sm-display="flex"
					sm-grid-gap="36px"
				>
					<Box
						text-align="center"
						lg-margin="0px 45px 0px 0px"
						display="flex"
						flex-direction="column"
						grid-gap="16px"
						align-items="flex-start"
						width="100%"
						lg-width="45%"
						md-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12:14:01.885Z"
							display="block"
							width="200px"
							padding="16px 16px 16px 16px"
							background="white"
							height="94px"
							srcSet="https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 1000px) 100vw,(max-width: 1279px) 100vw,100vw"
						/>
						<Text
							margin="0px 0px 0px 0px"
							font="--lead"
							text-align="center"
							href="https://www.accaglobal.com/gb/en/member/find-an-accountant/directory-of-member/results.html?isocountry=RU&FirstName=Olga&Surname=Yatsenko&Location=&inputcountrysuspended=&orgid=ACCA&orby=FNA&ipp=5&pn=1&hid=&requestcount=1"
							target="_blank"
							rel="nofollow"
							color="--darkL2"
							padding="0px 0px 0px 16px"
						>
							Certified by{" "}
							<Link href="https://www.cysec.gov.cy/en-GB/home/" target="_blank" color="--primary" rel="nofollow">
								CySec
							</Link>
						</Text>
					</Box>
					<Box
						text-align="center"
						lg-margin="0px 45px 0px 0px"
						sm-margin="0px 0 30px 0px"
						display="flex"
						flex-direction="column"
						grid-gap="16px"
						align-items="flex-start"
						width="100%"
						lg-width="45%"
						md-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-acca-full-logo.svg?v=2024-01-05T12:33:47.791Z"
							display="block"
							flex="0 0 auto"
							width="200px"
							padding="16px 16px 16px 16px"
							background="white"
							height="94px"
						/>
						<Link
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--primary"
							text-align="center"
							href="https://www.accaglobal.com/gb/en/member/find-an-accountant/directory-of-member/results.html?isocountry=RU&FirstName=Olga&Surname=Yatsenko&Location=&inputcountrysuspended=&orgid=ACCA&orby=FNA&ipp=5&pn=1&hid=&requestcount=1"
							target="_blank"
							rel="nofollow"
							padding="0px 0px 0px 16px"
						>
							ACCA member
						</Link>
						<Text
							margin="0px 0px 0px 0px"
							font="--base"
							color="--darkL2"
							text-align="left"
							padding="0px 0px 0px 16px"
							md-max-width="540px"
						>
							The Association of Chartered Certified Accountants are a globally recognised professional accountancy body providing qualifications and advancing standards in accountancy worldwide.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section color="--dark">
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Q&A
			</Text>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--primary"
					category="md"
					icon={MdHelp}
				/>
				<Text as="h3" font="--headline3" margin="10px 0" sm-font="--lead">
					What are the rates?
				</Text>
				<Text
					as="p"
					font="--lead"
					margin="0px 0px 0px 0px"
					color="--darkL2"
					sm-font="--base"
				>
					Possible cooperation options:
				</Text>
				<List margin="0px 0px 8px 0px" padding="0px 0px 0px 20px" list-style-type="decimal" as="ol">
					<Text
						margin="0px 0px 0px 0px"
						as="p"
						color="--darkL2"
						font="--lead"
						sm-font="--base"
					>
						Fixed payment of €1,000 per month.
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						as="p"
						color="--darkL2"
						font="--lead"
						sm-font="--base"
					>
						Hourly rate of €50
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						as="p"
						color="--darkL2"
						font="--lead"
						sm-font="--base"
					>
						Hourly rate of €30 plus 2% of annual revenue.
					</Text>
				</List>
				<Text
					as="p"
					font="--lead"
					margin="0px 0px 0px 0px"
					color="--darkL2"
					sm-font="--base"
				>
					Rates are exclusive of VAT.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--primary"
					category="md"
					icon={MdHelp}
				/>
				<Text as="h3" font="--headline3" margin="10px 0" sm-font="--lead">
					What does it take to create a "substance" company?
				</Text>
				<Components.ShowMore once>
					<Override slot="Content">
						<Text
							as="p"
							font="--lead"
							margin="0px 0px 0px 0px"
							color="--darkL2"
							sm-font="--base"
						>
							To set up a "substance" company in Cyprus, i.e. a company that demonstrates the existence of real business and economic activity on the island, you will need the following:
						</Text>
					</Override>
					<Override
						slot="Button"
						color="white"
						font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						border-radius="12px"
						margin="18px 0px 0px 0px"
					/>
					<Override slot="MoreContent">
						<List margin="8px 0px 8px 0px" padding="0px 0px 0px 20px" list-style-type="decimal" as="ol">
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Physical presence
								</Strong>
								: Your company must have a physical address in Cyprus which can be used as a registered office.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Local directors and employees
								</Strong>
								: It is understood that the company will have at least one local director and possibly several local employees depending on the volume of activity.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Functional Business
								</Strong>
								: The company must be actively engaged in business in Cyprus, including entering into contracts, performing services or manufacturing goods.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Accounting and tax accounting
								</Strong>
								: You will need to maintain accounting and tax records in accordance with Cyprus law, including filing tax returns and paying taxes on time.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Opening a bank account
								</Strong>
								: The company must have a bank account in Cyprus to carry out financial transactions and receive payments.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Documentation of activities
								</Strong>
								: Documentation of the company's activities should be maintained, including contracts, invoices, checks, reports and other financial records to prove its actual activities.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Compliance with all laws and regulations
								</Strong>
								: The company must comply with all Cyprus laws and regulations, including registration and business requirements.
							</Text>
						</List>
						<Text
							as="p"
							font="--lead"
							margin="0px 0px 0px 0px"
							color="--darkL2"
							sm-font="--base"
						>
							These steps will help your company to demonstrate that it has a real business and economic activity in Cyprus, which is essential for establishing a business in Cyprus.
						</Text>
					</Override>
				</Components.ShowMore>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"657c2c8a8d4a0c0020281f8b"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});